import React, { PureComponent } from 'react';
import classNames from "classnames";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';



const styles = theme => ({
	layout: {
		width: 'auto',
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[ theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2) ]: {
			width: 900,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	footer: {
		marginTop: theme.spacing.unit * 8,
		borderTop: `1px solid ${theme.palette.divider}`,
		padding: `${theme.spacing.unit * 6}px 0`,
	},
});


const footers = [
	{
		title: 'Resources',
		options: [
			{ text: 'Home', link: '/', external: false },
			{ text: 'Free Trial', link: '/register', external: false },
			{ text: 'Existing User Login', link: 'https://app.sabertoothcharting.com', external: true },
		],
	},
	{
		title: 'Legal',
		options: [
			{ text: 'Privacy Policy', link: '/privacy-policy', external: false },
			{ text: 'Terms of Use', link: '/terms-of-use', external: false },
		],
	},
	{
		title: 'Contact Us',
		options: [
			{ text: 'Support: support@sabertoothcharting.com', external: false },
			{ text: 'General Inquiries: info@sabertoothcharting.com', external: false },
		],
	},
];



class Footer extends PureComponent {
	render() {
		const { classes } = this.props;
		return (
			<footer className={classNames(classes.footer, classes.layout)}>
				<Grid container spacing={32} justify="space-evenly">
					{footers.map(footer => (
						<Grid item xs key={footer.title}>
							<Typography variant="h6" color="textPrimary" gutterBottom>
								{footer.title}
							</Typography>
							{footer.options.map((item, index) => (
								<Typography key={index} variant="subtitle1" color="textSecondary">
									{(!!item.link && item.external) && <a href={item.link}>{item.text}</a>}
									{(!!item.link && !item.external) && <Link to={item.link}>{item.text}</Link>}
									{!item.link && item.text}
								</Typography>
							))}
						</Grid>
					))}
				</Grid>
			</footer>
		);
	}
}



export default withStyles(styles)(Footer);