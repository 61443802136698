import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Footer from 'Footer';
import Header from 'Header';
import TermsOfUseText from './TermsOfUseText';



const styles = theme => ({
	heroUnit: {
		backgroundColor: theme.palette.background.paper,
	},
	heroContent: {
		maxWidth: 600,
		margin: '0 auto',
		padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
	},
	layout: {
		width: 'auto',
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[ theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2) ]: {
			width: 1100,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
});



class TermsOfUse extends React.PureComponent {

	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<Header />
				<div>
					{/* Hero unit */}
					<div className={classes.heroUnit}>
						<div className={classes.heroContent}>
							<Typography component="h1" variant="h2" color="textPrimary" align="center" gutterBottom>
								Terms of Use
							</Typography>
						</div>
					</div>
					<main className={classes.layout}>
						<div className={classes.heroContent}>

							<TermsOfUseText />

						</div>

					</main>
				</div>
				{/* Footer */}
				<Footer />
				{/* End footer */}
			</React.Fragment>
		);
	}

}



TermsOfUse.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TermsOfUse);