import thunkMiddleware from "redux-thunk";
import { applyMiddleware, combineReducers, createStore } from 'redux';
import register, { actions as registerActions } from './Register/Register.redux';



export const reducers = combineReducers({
	register,
});


export const actions = {
	register: registerActions,
};




const store = applyMiddleware(
	thunkMiddleware,
)(createStore)(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());


export default store;