import axios from 'axios';



const base = process.env.REACT_APP_API_URL;
const url = `${base}/registration/create_account`;


function sendRequest(data) {
	let options = {
		method: 'post',
		url: url,
		withCredentials: true,
		headers: {},
		data: data,
	};

	return axios.request(options)
		.then((res) => {
			if(!res.data || !res.data.data || !res.data.data.success) {
				throw res;
			}
			return true;
		}).catch((err) => {
			if(err.response && err.response.data && err.response.data.data && err.response.data.data.errors) {
				throw err.response.data.data.errors;
			} else {
				// eslint-disable-next-line
				throw {messages: ['An unknown error occurred.']};
			}
		});
}

export default sendRequest;