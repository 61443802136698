import React from 'react';
import Typography from "@material-ui/core/Typography/Typography";




const TermsOfUseText = (props) => (
	<React.Fragment>

		<Typography variant={'caption'} paragraph>
			This document was last updated on November 14, 2018.
		</Typography>

		<Typography variant="h6">
			<strong>Terms of Use</strong>
		</Typography>

		<Typography paragraph>
			Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the
			sabertoothcharting.com (change this) website charting service (the "Service") operated by Sabertooth
			Charting ("us", "we", or "our").
		</Typography>

		<Typography paragraph>
			Your access to and use of the Service is conditioned on your acceptance of and compliance with
			these Terms. These Terms apply to all visitors, users and others who access or use the Service.
		</Typography>

		<Typography variant="h6">
			<strong>Registration</strong>
		</Typography>

		<Typography paragraph>
			Users are required to register an account to use the Service. Users are entitled to a single free trial
			for each clinic registered. We reserve the right to deny access to the Service to any users caught abusing
			the free trial offer.
		</Typography>


		<Typography variant="h6">
			<strong>Inactive Accounts</strong>
		</Typography>

		<Typography paragraph>
			We retain all data on inactive clinic accounts for one year from the date of account expiration. After an
			account has been expired for one year, we remove all user-provided information except basic clinic data and
			user accounts. Deleted data includes, but is not limited to, all information on clients and patients, all
			non-primary contact information, charting data, reports, and uploaded files.
		</Typography>

		<Typography paragraph>
			Clinic administrators will receive at least two notifications via email to inform of automatic data
			deletion. The first email will be sent no fewer than 60 days before data deletion is scheduled to occur.
			A second email will be sent no fewer than seven days before deletion is scheduled to occur. A separate
			notification will be sent to clinic administrators to inform them that their data has been automatically
			deleted due to extended account inactivity.
		</Typography>

		<Typography paragraph>
			Clinic administrators with expired accounts may contact us at any time, so long as said time is at least 30
			days before scheduled data deletion, to request the raw form of their clinic's data from the Service,
			ree of charge. Failure to request data without notice of at least 30 days may result in data being
			unrecoverable.
		</Typography>


		<Typography variant="h6">
			<strong>Account Deletion</strong>
		</Typography>

		<Typography paragraph>
			You may request that your data be deleted from the Service at any time. Requesting deletion of your data
			will initiate a 30 day timer, at which point an automated email notification will be sent containing
			instructions on finalizing deletion. If the deletion process is not finalized within 14 days of this
			notification, the deletion request will expire.
		</Typography>

		<Typography paragraph>
			If you are the sole user attached to a clinic, requesting deletion of your account will also result in
			removal of all clinic data. When requesting account removal as a clinic administrator, you will be required
			to ask if you intend only your account to be removed, or all clinic data. If you intend for only your
			account to be removed, another user will be required to be promoted to administrator prior to account
			removal.
		</Typography>


		<Typography variant="h6">
			<strong>Payments and Refunds</strong>
		</Typography>

		<Typography paragraph>
			Payment for the Service is accepted online via credit card only. Refunds are not available unless a charge
			has been deemed fraudulent. The Service is provided on a month-to-month basis. Billing is automatic each
			month, and may be canceled at any time. Canceled accounts remain active until their expiration date.
		</Typography>

		<Typography paragraph>
			Failure of automatic billing will result in a notification email being sent to the billing contact. If the
			issue is not resolved within 30 days, the clinic account in question will be deactivated. Accounts
			deactivated due to lapsed payments will maintain a balance due of the cost of one month of service based on
			the plan in use at the time of deactivation. This balance must be paid, along with the next month of
			service, in order to reactivate the account.
		</Typography>

		<Typography paragraph>
			We have zero tolerance for fraud. Providing fraudulent payment information will result in immediate removal
			of all clinic data. Performing a chargeback on a legitimate payment will result in immediate deactivation of
			all clinic accounts. If the chargeback is not canceled within 14 days, all clinic data for the account will
			be removed.
		</Typography>


		<Typography variant="h6">
			<strong>Use of Service</strong>
		</Typography>

		<Typography paragraph>
			Users shall have access to all features provided by the plan chosen for their clinic. If an issue is found
			allowing access to otherwise-unavailable features or avoidance of account limits, please notify our support
			department. If we determine that an account is intentionally exploiting unknown issues to avoid account
			restrictions, the account will be immediately deactivated and the user notified of our decision, along with
			any next steps.
		</Typography>

		<Typography paragraph>
			We make strong efforts to keep your data safe. If you come across any issue which provides information
			from clinics that your account does not have access to, please report it to our support department
			immediately. Accounts caught intentionally accessing data from clinics without permission will be
			immediately terminated. Legal action may be taken against any person or persons accessing restricted
			information.
		</Typography>


		<Typography variant="h6">
			<strong>Limitation of Liability</strong>
		</Typography>

		<Typography paragraph>
			The Service is provided as-is, with no guarantees. We make every effort to keep downtime to a minimum, and
			may offer service credit in the event of excessive downtime, but reserve the right to offer no recourse for
			any disruptions of service. We maintain no liability for loss of data, unintentional misuse of data, injury,
			other other loss as a result of your use of the Service, except as required by law.
		</Typography>

	</React.Fragment>
);

export default TermsOfUseText;