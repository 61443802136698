import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import { actions } from './Register.redux';
import RegisterClinic from './Register.clinic';
import RegisterUser from './Register.user';
import RegisterTerms from './Register.terms';
import RegisterRestrictedSignup from './RegisterRestrictedSignup';
import Footer from 'Footer';



const styles = theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.grey[ 100 ],
		},
	},
	appBar: {
		position: 'relative',
	},
	toolbarTitle: {
		flex: 1,
	},
	layout: {
		width: 'auto',
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[ theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2) ]: {
			width: 900,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	heroContent: {
		maxWidth: 600,
		margin: '0 auto',
		padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
	},
	cardHeader: {
		backgroundColor: theme.palette.grey[ 200 ],
	},
	cardPricing: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'baseline',
		marginBottom: theme.spacing.unit * 2,
	},
	cardActions: {
		[ theme.breakpoints.up('sm') ]: {
			paddingBottom: theme.spacing.unit * 2,
		},
	},
	expansionRoot: {
		width: '100%',
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: 'red',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
	},
});




class Register extends React.PureComponent {

	handleLogin = e => {
		window.location.href = "https://app.sabertoothcharting.com";
	};


	render() {
		const props                                                            = this.props;
		const { classes, setPanel, panel, acceptBetaTerms, registered, login } = props;

		const { generalErrors, userErrors, clinicErrors } = props;

		return (
			<React.Fragment>
				<CssBaseline />
				<AppBar position="static" color="default" className={classes.appBar}>
					<Toolbar>
						<Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
							Sabertooth Charting
						</Typography>
						<Button color="primary" variant="outlined" onClick={this.handleLogin}>
							Existing User Login
						</Button>
					</Toolbar>
				</AppBar>

				{!acceptBetaTerms &&
				<main className={classes.layout}>
					<div className={classes.heroContent}>
						<Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
							Register
						</Typography>
						<Typography variant="h6" align="center" color="textSecondary" component="p">
							You must agree to the software beta terms below.
						</Typography>
					</div>

					<div>
						<RegisterRestrictedSignup />
					</div>
				</main>
				}

				{(acceptBetaTerms && !registered) &&
				<main className={classes.layout}>
					{/* Hero unit */}
					<div className={classes.heroContent}>
						<Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
							Register
						</Typography>
						<Typography variant="h6" align="center" color="textSecondary" component="p">
							Register for the free beta today! No credit card required. Starting at $79/month after the
							beta period ends. You will receive at least 30 days notice prior to the beta ending in order
							to have time to evaluate the software and set up a subscription if you wish to continue.
							No extra fees or long-term contracts, cancel any time.
						</Typography>
					</div>
					{/* End hero unit */}
					<Grid container spacing={40} alignItems="flex-end">
						<Grid item xs={12}>
							<ExpansionPanel expanded={panel === 'clinic'} onChange={() => (setPanel('clinic'))}>
								<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
									<Typography className={classes.heading}>Clinic Info</Typography>
									{clinicErrors &&
									<Typography className={classes.secondaryHeading}>
										Errors found, click here to review
									</Typography>
									}
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<RegisterClinic />
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Grid>


						<Grid item xs={12}>
							<ExpansionPanel expanded={panel === 'user'} onChange={() => (setPanel('user'))}>
								<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
									<Typography className={classes.heading}>User Info</Typography>
									{userErrors &&
									<Typography className={classes.secondaryHeading}>
										Errors found, click here to review
									</Typography>
									}
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<RegisterUser />
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Grid>


						<Grid item xs={12}>
							<ExpansionPanel expanded={panel === 'terms'} onChange={() => (setPanel('terms'))}>
								<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
									<Typography className={classes.heading}>
										Terms of Service &amp; Privacy Policy
									</Typography>
									{generalErrors &&
									<Typography className={classes.secondaryHeading}>
										Errors found, click here to review
									</Typography>
									}
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<RegisterTerms />
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Grid>
					</Grid>
				</main>
				}

				{registered &&
				<main className={classes.layout}>
					{/* Hero unit */}
					<div className={classes.heroContent}>
						<Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
							Registration Complete!
						</Typography>
						<Typography paragraph variant="h6" align="center" color="textSecondary" component="p">
							It's time to give Sabertooth Charting a spin! Click the
							<strong> Existing User Login</strong> button in the top right corner of the screen, or
							visit <a href="https://app.sabertoothcharting.com">app.sabertoothcharting.com</a> from
							any device. Simply use <strong>{login}</strong> as your username along with the password you
							provided!
						</Typography>
						<Typography variant="h6" align="center" color="textSecondary" component="p">
							If you run into any issues, we're here to help. Send us an email at
							<strong> support@sabertoothcharting.com</strong> and we'll get back to you within one
							business
							day.
						</Typography>
					</div>
					{/* End hero unit */}
				</main>
				}
				{/* Footer */}
				<Footer />
				{/* End footer */}
			</React.Fragment>
		);
	}

}



Register.propTypes = {
	classes: PropTypes.object.isRequired,
	panel: PropTypes.string.isRequired,
};


const mapStateToProps = state => ({
	panel: state.register.panel,
	acceptBetaTerms: state.register.acceptBetaTerms,
	registered: state.register.registered,
	login: state.register.user.user_email,
	generalErrors: !!state.register.messages.length,
	clinicErrors: !!state.register.clinicErrors.messages.length,
	userErrors: !!state.register.userErrors.messages.length,
});

const mapDispatchToProps = dispatch => ({
	setPanel: (panel) => {
		dispatch(actions.setState({ panel }))
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Register));