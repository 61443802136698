import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import { connect } from 'react-redux';
import { actions } from 'store';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';



const styles = theme => ({
	divider: {
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit * 2,
	},
	previousButton: {
		float: 'left',
		margin: theme.spacing.unit,
	},
	nextButton: {
		float: 'right',
		margin: theme.spacing.unit,
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	red: {
		color: 'red',
	},
});



class RegisterTerms extends React.PureComponent {

	handleNextSection = (e) => {
		this.props.setState({ panel: 'terms' });
	};


	handlePreviousSection = (e) => {
		this.props.setState({ panel: 'user' });
	};


	handleChange = e => {
		this.props.setState({ [ e.target.name ]: e.target.checked });
	};


	render() {
		const { classes, acceptTerms, acceptPrivacy, waiting, errors, generalErrors, clinicErrors, userErrors } = this.props;

		return (
			<Grid container spacing={16}>

				{!!errors.length &&
				<Grid item xs={12}>
					<Typography>
						The following errors were encountered when submitting your registration:
					</Typography>

					<ul>
						{
							errors.map((err, index) => {
								return <li key={index}>{err}</li>;
							})
						}
					</ul>
				</Grid>
				}

				<Grid item xs={12}>
					<Divider className={classes.divider} />
					<Typography>
						You must accept both items below.
					</Typography>
				</Grid>


				<Grid item xs={12}>
					<FormControl required component="fieldset" className={classes.formControl}>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										name="acceptTerms"
										checked={acceptTerms}
										onChange={this.handleChange}
										value="terms"
									/>
								}
								label="I agree to the Sabertooth Charting Terms and Conditions"
							/>
							<FormControlLabel
								control={
									<Checkbox
										name="acceptPrivacy"
										checked={acceptPrivacy}
										onChange={this.handleChange}
										value="privacy"
									/>
								}
								label="I accept the Sabertooth Charting Privacy Policy"
							/>
						</FormGroup>
					</FormControl>
				</Grid>


				<Grid item xs={6}>
					<Button
						color="secondary"
						variant="contained"
						size="large"
						className={classes.previousButton}
						onClick={this.handlePreviousSection}
					>
						Previous Section
					</Button>
				</Grid>

				<Grid item xs={6}>
					<Button
						color="primary"
						variant="contained"
						size="large"
						className={classes.nextButton}
						onClick={this.props.register}
						disabled={!acceptTerms || !acceptPrivacy || waiting}
					>
						Register
					</Button>
				</Grid>

				{(generalErrors || clinicErrors || userErrors) &&
				<Grid item xs={12}>
					<Typography className={classes.red}>
						Errors were encountered while submitting your registration details. Please review the previous
						sections for errors, then try submitting this form again.
					</Typography>
				</Grid>
				}
			</Grid>

		);
	}

}



const mapStateToProps = state => ({
	acceptTerms: state.register.acceptTerms,
	acceptPrivacy: state.register.acceptPrivacy,
	waiting: state.register.waiting,
	errors: state.register.messages,
	generalErrors: !!state.register.messages.length,
	clinicErrors: !!state.register.clinicErrors.messages.length,
	userErrors: !!state.register.userErrors.messages.length,
});

const mapDispatchToProps = dispatch => ({
	setState: (data) => (dispatch(actions.register.setState(data))),
	register: () => (dispatch(actions.register.submitRegistration())),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegisterTerms));