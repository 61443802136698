import React from 'react';
import { connect } from 'react-redux';
import { actions } from 'store';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';



const RegisterRestrictedSignup = React.memo(function RegisterRestrictedSignup(props) {
	const { submit } = props;

	function handleSubmit(e) {
		e.preventDefault();
		submit();
	}

	return (
		<form onSubmit={handleSubmit}>
			<Grid container spacing={40}>
				<Grid item xs={12}>
					<Typography gutterBottom>
						Sabertooth Charting is currently in beta. While we intend to carry over all data from the beta
						to our final launch, we cannot guarantee that everything will be able to be carried forward. As
						such, Sabertooth Charting should not yet be relied on as your sole charting solution. By
						clicking the Agree and Register button and signing up for this beta, you indicate that you
						understand the risks associated with using incomplete software.
					</Typography>
					<div>
						<Button
							onClick={handleSubmit}
							variant="contained"
							color="primary"
							size="large"
						>
							Agree and Register
						</Button>
					</div>
				</Grid>


			</Grid>

		</form>
	);
});


const mapDispatchToProps = dispatch => ({
	submit: () => (dispatch(actions.register.acceptBetaTerms())),
});


export default connect(null, mapDispatchToProps)(RegisterRestrictedSignup);