import React from 'react';
import Typography from "@material-ui/core/Typography/Typography";




const PrivacyPolicyText = (props) => (
	<React.Fragment>
		<Typography variant={'caption'} paragraph>
			This document was last updated on November 14, 2018.
		</Typography>

		<Typography variant="h6">
			<strong>Privacy Notice</strong>
		</Typography>

		<Typography paragraph>
			This privacy notice discloses the privacy practices for sabertoothcharting.com. This
			privacy notice applies solely to information collected by this website. It will notify
			you of the following:
		</Typography>


		<ol>
			<li>What personally identifiable information is collected from you through the website,
				how it is used and with whom it may be shared.
			</li>
			<li>What choices are available to you regarding the use of your data.</li>
			<li>The security procedures in place to protect the misuse of your information.</li>
			<li>How you can correct any inaccuracies in the information.</li>
		</ol>


		<Typography variant="h6">
			<strong>Information Collection, Use, and Sharing</strong>
		</Typography>

		<Typography paragraph>
			We are the sole owners of the information collected on this site. We only have access
			to/collect information that you voluntarily give us via our services, email or other
			direct contact from you. We will not sell or rent this information to anyone.
		</Typography>

		<Typography paragraph>
			We will use your information to respond to you, regarding the reason you contacted us.
			We will not share your information with any third party outside of our organization,
			other than as necessary to fulfill your request.
		</Typography>

		<Typography paragraph>
			We may use anonymized, aggregated data collected from the use of our charting
			application to help us improve our service.
		</Typography>

		<Typography paragraph>
			Unless you ask us not to, we may contact you via email in the future to tell you about
			specials, new products or services, or changes to this privacy policy.
		</Typography>


		<Typography variant="h6">
			<strong>Your Access to and Control Over Information</strong>
		</Typography>

		<Typography paragraph>
			You may opt out of any future contacts from us at any time. You can do the following at
			any time by contacting us via the email address given on our website:
		</Typography>

		<ol>
			<li>See what data we have about you, if any.</li>
			<li>Change/correct any data we have about you.</li>
			<li>Have us delete any data we have about you.</li>
			<li>Express any concern you have about our use of your data.</li>
		</ol>


		<Typography variant="h6">
			<strong>Security</strong>
		</Typography>

		<Typography paragraph>
			We take precautions to protect your information. When you submit sensitive information
			via the website, your information is protected both online and offline.
		</Typography>

		<Typography paragraph>
			Wherever we collect sensitive information (such as credit card data), that information
			is encrypted and transmitted to us in a secure way. You can verify this by looking for a
			lock icon in the address bar and looking for "https" at the beginning of the address of
			the Web page.
		</Typography>

		<Typography paragraph>
			While we use encryption to protect sensitive information transmitted online, we also
			protect your information offline. Only employees who need the information to perform a
			specific job (for example, billing or customer service) are granted access to personally
			identifiable information. The computers/servers in which we store personally
			identifiable information are kept in a secure environment.
		</Typography>


		<Typography variant="h6">
			<strong>Registration</strong>
		</Typography>

		<Typography paragraph>
			In order to use the Sabertooth Charting application, a user must first complete the
			registration form. During registration a user is required to give certain information
			(such as name and email address). This information is used to create your account, as
			well as to provide important updates about the service. We do not collect any
			demographic information, such as gender or age.
		</Typography>


		<Typography variant="h6">
			<strong>Billing</strong>
		</Typography>

		<Typography paragraph>
			Our service is offered with a free trial, after which payment via credit card is
			required. You will be required to provide information such as name, phone number,
			billing address, and credit card number to continue using the service. We will not use
			this information for purposes other than billing or to contact you regarding billing
			issues.
		</Typography>


		<Typography variant="h6">
			<strong>Cookies</strong>
		</Typography>

		<Typography paragraph>
			We use cookies on this site. A cookie is a piece of data stored on a site visitor's
			hard drive to help us improve your access to our site and identify repeat visitors to
			our site. For instance, when we use a cookie to identify you, you would not have to log
			in a password on every visit. Usage of a cookie is in no way linked to any personally
			identifiable information on our site, nor do we use cookies for marketing purposes.
		</Typography>


		<Typography variant="h6">
			<strong>Links</strong>
		</Typography>

		<Typography paragraph>
			This website may contain links to other sites. Please be aware that we are not
			responsible for the content or privacy practices of such other sites. We encourage our
			users to be aware when they leave our site and to read the privacy statements of any
			other site that collects personally identifiable information.
		</Typography>
	</React.Fragment>
);

export default PrivacyPolicyText;