import React, { PureComponent } from 'react';
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar/AppBar";
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import logo from 'images/sabertooth-logo.png';
import Hidden from '@material-ui/core/Hidden';



class Header extends PureComponent {

	handleLogin = e => {
		window.location.href = "https://app.sabertoothcharting.com";
	};

	handleRegister = e => {
		window.location.href = "/register";
	};

	goHome = e => {
		window.location.href = "/";
	};


	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<CssBaseline />
				<AppBar position="static" className={classes.appBar}>

					<Toolbar>
						<div className={classes.logo} onClick={this.goHome} />

						<div className={classes.toolbarTitle} />

						<Hidden only={"xs"}>
							<Button
								color="inherit"
								variant="outlined"
								onClick={this.handleRegister}
								className={classes.buttonMargin}
							>
								Free Trial
							</Button>
						</Hidden>

						<Button color="inherit" variant="outlined" onClick={this.handleLogin}>
							Existing User Login
						</Button>
					</Toolbar>
				</AppBar>
			</React.Fragment>
		);
	}
}



const styles = theme => ({
	appBar: {
		position: 'relative',
	},
	toolbarTitle: {
		flex: 1,
	},
	buttonMargin: {
		marginRight: theme.spacing.unit * 2,
	},
	logo: {
		position: 'absolute',
		top: 0,
		left: 10,
		width: 180,
		height: 90,
		backgroundImage: `url(${logo})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		cursor: 'pointer',
	},
});



export default withStyles(styles)(Header);