import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ConnectedField from './ConnectedField';
import { connect } from 'react-redux';
import { actions } from 'store';



const styles = theme => ({
	divider: {
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit * 2,
	},
	nextButton: {
		float: 'right',
		margin: theme.spacing.unit,
	},
});



class RegisterClinic extends React.PureComponent {

	handleNextSection = (e) => {
		this.props.setState({ panel: 'user' });
	};


	render() {
		const { classes, errors } = this.props;

		return (
			<Grid container spacing={40}>

				{!!errors.length &&
				<Grid item xs={12}>
					<Typography>
						The following errors were encountered when submitting your registration:
					</Typography>

					<ul>
						{
							errors.map((err, index) => {
								return <li key={index}>{err}</li>;
							})
						}
					</ul>
				</Grid>
				}

				<Grid item xs={12}>
					<Typography>
						All fields marked with an asterisk (*) are required.
					</Typography>
				</Grid>

				<Grid item xs={12} md={6}>

					<Divider className={classes.divider} />
					<Typography>
						Basic Information
					</Typography>

					<div>
						<ConnectedField
							datakey="clinic"
							name="clinic_name"
							label="Clinic Name"
							required
						/>
					</div>


					<Divider className={classes.divider} />
					<Typography>
						Contact Information
					</Typography>

					<div>
						<ConnectedField
							datakey="clinic"
							name="clinic_email"
							label="Clinic Email Address"
							required
						/>
					</div>

					<div>
						<ConnectedField
							datakey="clinic"
							name="clinic_phone"
							label="Clinic Phone Number"
						/>
					</div>
				</Grid>


				<Grid item xs={12} md={6}>
					<Divider className={classes.divider} />
					<Typography>
						Primary Clinic Address
					</Typography>

					<div>
						<ConnectedField
							datakey="clinic"
							name="address_line_1"
							label="Address Line 1"
							helperText="Street Address, PO Box, Company name, C/O"
							required
						/>
					</div>

					<div>
						<ConnectedField
							datakey="clinic"
							name="address_line_2"
							label="Address Line 2"
							helperText="Apartment, Suite, Unit, Building, Floor, etc."
						/>
					</div>

					<div>
						<ConnectedField
							datakey="clinic"
							name="address_city"
							label="City"
							required
						/>
					</div>

					<div>
						<ConnectedField
							datakey="clinic"
							name="address_state"
							label="State/Province/Region"
							required
						/>
					</div>

					<div>
						<ConnectedField
							datakey="clinic"
							name="address_postal_code"
							label="ZIP/Postal Code"
							required
						/>
					</div>

					<div>
						<ConnectedField
							datakey="clinic"
							name="address_country"
							label="Country"
							required
						/>
					</div>
				</Grid>


				<Grid item xs={12}>
					<Button
						color="primary"
						variant="contained"
						size="large"
						className={classes.nextButton}
						onClick={this.handleNextSection}
					>
						Next Section
					</Button>
				</Grid>

			</Grid>
		);
	}
}



const mapStateToProps = state => ({
	errors: state.register.clinicErrors.messages,
});

const mapDispatchToProps = dispatch => ({
	setState: (data) => (dispatch(actions.register.setState(data))),
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegisterClinic));