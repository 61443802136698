import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import heroImage from '../images/charting-view.png';
import reportImage from '../images/report.png';
import browserImage from '../images/browsers.jpg';
import { Link } from 'react-router-dom';
import Footer from 'Footer';
import Header from 'Header';



const styles = theme => ({
	heroUnit: {
		backgroundColor: theme.palette.grey[ 100 ],
		background: `url('${heroImage}')`,
		backgroundSize: 'auto 120%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right center',
	},
	heroContent: {
		backgroundColor: 'rgba(245, 245, 245, 0.75)',
		maxWidth: 1100,
		marginTop: 0,
		marginBottom: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		padding: `${theme.spacing.unit * 8}px 16px ${theme.spacing.unit * 6}px`,
	},
	heroButtons: {
		marginTop: theme.spacing.unit * 4,
	},
	layout: {
		width: 'auto',
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[ theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2) ]: {
			width: 1100,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	cardGrid: {
		padding: `${theme.spacing.unit * 8}px 0`,
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	cardMedia: {
		paddingTop: '56.25%', // 16:9
	},
	cardContent: {
		flexGrow: 1,
	},
	footer: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing.unit * 6,
	},
});



class Home extends React.PureComponent {

	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<Header />
				<main>
					{/* Hero unit */}
					<div className={classes.heroUnit}>
						<div className={classes.heroContent}>
							<Typography component="h1" variant="h2" color="textPrimary" gutterBottom>
								A Better Charting Solution
							</Typography>
							<Typography variant="h6" color="textSecondary" paragraph>
								Sabertooth Charting redefines veterinary dental charting.
								Create charts from anywhere, using any device, with our easy-to-use web interface.
								Save time with our automated reporting tools. You'll never want to go back to your old
								charting
								solution.<br />
								Sign up for a free trial today - no credit card required.
							</Typography>
							<div className={classes.heroButtons}>
								<Grid container spacing={16} justify="center">
									<Grid item>
										<Link to="/register">
											<Button variant="contained" color="primary">
												Sign Up
											</Button>
										</Link>
									</Grid>
								</Grid>
							</div>
						</div>
					</div>
					<div className={classNames(classes.layout, classes.cardGrid)}>
						{/* End hero unit */}
						<Grid container spacing={40}>

							<Grid item sm={12} md={4}>
								<Card className={classes.card}>
									<CardMedia
										className={classes.cardMedia}
										image={heroImage}
										title="Image title"
									/>
									<CardContent className={classes.cardContent}>
										<Typography gutterBottom variant="h5" component="h2">
											Easy Charting
										</Typography>
										<Typography>
											Simple, easy-to-understand charting interface. Use it on a desktop,
											laptop, or tablet of your choice. Full set of AVDC diagnostics and
											treatment codes available at your fingertips. Adult, deciduous, and mixed
											dentition views available.
										</Typography>
									</CardContent>
									{/*<CardActions>
										<Button size="small" color="primary">
											Learn More
										</Button>
									</CardActions>*/}
								</Card>
							</Grid>

							<Grid item sm={12} md={4}>
								<Card className={classes.card}>
									<CardMedia
										className={classes.cardMedia}
										image={reportImage}
										title="Image title"
									/>
									<CardContent className={classes.cardContent}>
										<Typography gutterBottom variant="h5" component="h2">
											Integrated Reporting
										</Typography>
										<Typography>
											Stop spending so much time on reports. Our integrated reporting tool can
											fill in names, addresses, charting values, and images. Just type in any
											extra notes and hit print.
										</Typography>
									</CardContent>
									{/*<CardActions>
										<Button size="small" color="primary">
											Learn More
										</Button>
									</CardActions>*/}
								</Card>
							</Grid>

							<Grid item sm={12} md={4}>
								<Card className={classes.card}>
									<CardMedia
										className={classes.cardMedia}
										image={browserImage}
										title="Image title"
									/>
									<CardContent className={classes.cardContent}>
										<Typography gutterBottom variant="h5" component="h2">
											Zero Setup
										</Typography>
										<Typography>
											Sabertooth Charting is web-based and works on any device with a modern web
											browser. No downloads, installs, or operating system restrictions. Chrome,
											Safari, Firefox, and Edge are supported - mobile and desktop.
										</Typography>
									</CardContent>
									{/*<CardActions>
										<Button size="small" color="primary">
											Learn More
										</Button>
									</CardActions>*/}
								</Card>
							</Grid>

						</Grid>
					</div>
				</main>
				{/* Footer */}
				<Footer />
				{/* End footer */}
			</React.Fragment>
		);
	}

}



Home.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);