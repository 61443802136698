import moize from 'moize';
import create_account from './requests/create_account';



export const types = {
	SET_STATE: 'REGISTER/SET_STATE',
	SET_SUBSTATE: 'REGISTER/SET_SUBSTATE',
};


export const initialState = {
	clinic: {
		clinic_name: '',
		clinic_email: '',
		clinic_phone: '',
		address_line_1: '',
		address_line_2: '',
		address_city: '',
		address_state: '',
		address_postal_code: '',
		address_country: '',
	},
	clinicErrors: {
		clinic_name: false,
		clinic_email: false,
		clinic_phone: false,
		address_line_1: false,
		address_line_2: false,
		address_city: false,
		address_state: false,
		address_postal_code: false,
		address_country: false,
		messages: [],
	},

	user: {
		user_name: '',
		user_email: '',
		user_email_verify: '',
		user_password: '',
		user_password_verify: '',
	},
	userErrors: {
		user_name: false,
		user_email: false,
		user_email_verify: false,
		user_password: false,
		user_password_verify: false,
		messages: [],
	},

	acceptTerms: false,
	acceptPrivacy: false,
	acceptBetaTerms: false,
	signupError: false,
	panel: 'clinic',
	messages: [],
	registered: false,
	waiting: false,
};


export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };

		case types.SET_SUBSTATE:
			if(!action.name) {
				return {
					...state,
					clinic: { ...state.clinic, ...action.data },
				};
			}
			return {
				...state,
				[ action.name ]: { ...state[ action.name ], ...action.data },
			};

		default:
			return state;
	}
}

export const actions = {
	setState: (data) => ({
		type: types.SET_STATE,
		data,
	}),


	updateField: moize((name) => (e) => ({
		type: types.SET_SUBSTATE,
		name: name,
		data: { [ e.target.name ]: e.target.value },
	})),


	submitRegistration: () => ((dispatch, getState) => {
		let state = getState().register;
		let errors = validateRegistration(state);

		if(errors) {
			dispatch({
				type: types.SET_STATE,
				data: errors,
			});
			return false;
		}

		dispatch({
			type: types.SET_STATE,
			data: {
				waiting: true,
			},
		});

		let accountData = {
			clinic: state.clinic,
			user: state.user,
			acceptTerms: state.acceptTerms,
			acceptPrivacy: state.acceptPrivacy,
		};

		create_account(accountData).then(res => {
			dispatch({
				type: types.SET_STATE,
				data: {
					waiting: false,
					registered: true,
				},
			});
		}).catch(err => {
			dispatch({
				type: types.SET_STATE,
				data: {
					waiting: false,
					registered: false,
					...err,
				},
			});
		});
	}),


	acceptBetaTerms: () => ({ type: types.SET_STATE, data: { acceptBetaTerms: true } }),
};



function validateRegistration(state) {
	let hasErrors = false, clinicRequiredError = false, userRequiredError = false;
	let errors = {
		clinicErrors: {
			...initialState.clinicErrors,
			messages: [],
		},
		userErrors: {
			...initialState.userErrors,
			messages: [],
		},
	};

	let clinicRequired = [
		'clinic_name',
		'clinic_email',
		'address_line_1',
		'address_city',
		'address_state',
		'address_postal_code',
		'address_country',
	];
	let userRequired = [
		'user_name',
		'user_email',
		'user_email_verify',
		'user_password',
		'user_password_verify',
	];

	// Verify that all clinic info has been provided
	for(let i = 0; i < clinicRequired.length; i++) {
		let key = clinicRequired[ i ];
		if(!state.clinic[ key ].trim()) {
			errors.clinicErrors[ key ] = true;
			hasErrors = true;
			clinicRequiredError = true;
		}
	}

// Verify that all user info has been provided
	for(let i = 0; i < userRequired.length; i++) {
		let key = userRequired[ i ];
		if(!state.user[ key ].trim()) {
			errors.userErrors[ key ] = true;
			hasErrors = true;
			userRequiredError = true;
		}
	}

	let clinic = state.clinic, user = state.user;

	if(!validateEmail(clinic.clinic_email)) {
		hasErrors = true;
		errors.clinicErrors.clinic_email = true;
		errors.clinicErrors.messages.push('Clinic Email must be a valid email address.');
	}

	if(!validateEmail(user.user_email)) {
		hasErrors = true;
		errors.userErrors.user_email = true;
		errors.userErrors.messages.push('User Email must be a valid email address.');
	}

	if(!validateEmail(user.user_email_verify)) {
		hasErrors = true;
		errors.userErrors.user_email = true;
		errors.userErrors.messages.push('User Email Verification must be a valid email address.');
	}

	if(user.user_email !== user.user_email_verify) {
		hasErrors = true;
		errors.userErrors.user_email_verify = true;
		errors.userErrors.messages.push('User Email Verification must match originally entered email address.');
	}

	if(user.user_password.length < 10) {
		hasErrors = true;
		errors.userErrors.user_password = true;
		errors.userErrors.messages.push('Password must be at least 10 characters.');
	}

	if(user.user_password_verify.length < 10) {
		hasErrors = true;
		errors.userErrors.clinic_email = true;
		errors.userErrors.messages.push('Password verification must be at least 10 characters.');
	}

	if(user.user_password !== user.user_password_verify) {
		hasErrors = true;
		errors.userErrors.user_password_verify = true;
		errors.userErrors.messages.push('Password Verification must match originally entered password.');
	}

	if(userRequiredError) {
		errors.userErrors.messages.unshift('All fields marked with an asterisk (*) are required.');
	}
	if(clinicRequiredError) {
		errors.clinicErrors.messages.unshift('All fields marked with an asterisk (*) are required.');
	}

	if(!hasErrors) return null;
	return errors;
}


function validateEmail(email) {
	// eslint-disable-next-line
	let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}