import React from 'react';
import { connect } from 'react-redux';
import TextField from "@material-ui/core/TextField/TextField";
import PropTypes from 'prop-types';
import { actions } from 'store';


const ConnectedField = props => {
	const { name, updateField, value, error, ...rest } = props;

	return (
		<TextField
			name={name}
			variant="outlined"
			margin="normal"
			value={value}
			onChange={updateField}
			fullWidth
			error={error}
			{...rest}
		/>
	);
};



ConnectedField.defaultProps = {
	datakey: '',
};

ConnectedField.propTypes = {
	datakey: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string,
	error: PropTypes.bool.isRequired,
	updateField: PropTypes.func.isRequired,
};


const mapStateToProps = (state, ownProps) => {
	let value = ownProps.datakey
		? state.register[ ownProps.datakey ][ ownProps.name ]
		: state.register[ ownProps.name ];

	let error = ownProps.datakey
		? state.register[ `${ownProps.datakey}Errors` ][ ownProps.name ]
		: false;

	return {
		value: value,
		error: error,
	}
};


const mapDispatchToProps = (dispatch, ownProps) => ({
	updateField: (e) => (dispatch(actions.register.updateField(ownProps.datakey)(e))),
});



export default connect(mapStateToProps, mapDispatchToProps)(ConnectedField);