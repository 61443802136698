import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Home from './Home';
import Register from './Register';
import PrivacyPolicy from 'PrivacyPolicy';
import TermsOfUse from 'TermsOfUse';



class Router extends React.PureComponent {
	render() {


		return (
			<BrowserRouter>
				<ScrollToTop>
					<Route
						render={({ location }) => (
							<Switch
								location={location}
								key={location.key}
							>
								<Route
									exact
									path="/"
									component={Home}
								/>
								<Route
									exact
									path="/register"
									component={Register}
								/>
								<Route
									exact
									path="/privacy-policy"
									component={PrivacyPolicy}
								/>
								<Route
									exact
									path="/terms-of-use"
									component={TermsOfUse}
								/>
							</Switch>
						)}
					/>
				</ScrollToTop>
			</BrowserRouter>
		)
	}
}



export default Router;