import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ConnectedField from './ConnectedField';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import { connect } from 'react-redux';
import { actions } from 'store';



const styles = theme => ({
	divider: {
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit * 2,
	},
	previousButton: {
		float: 'left',
		margin: theme.spacing.unit,
	},
	nextButton: {
		float: 'right',
		margin: theme.spacing.unit,
	},
});



class RegisterUser extends React.PureComponent {

	handleNextSection = (e) => {
		this.props.setState({ panel: 'terms' });
	};


	handlePreviousSection = (e) => {
		this.props.setState({ panel: 'clinic' });
	};


	render() {
		const { classes, errors } = this.props;

		return (
			<Grid container spacing={16}>

				{!!errors.length &&
				<Grid item xs={12}>
					<Typography>
						The following errors were encountered when submitting your registration:
					</Typography>

					<ul>
						{
							errors.map((err, index) => {
								return <li key={index}>{err}</li>;
							})
						}
					</ul>
				</Grid>
				}

				<Grid item xs={12}>
					<Typography>
						All fields marked with an asterisk (*) are required.
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<Divider className={classes.divider} />
					<Typography>
						User Login Information
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<ConnectedField
						datakey="user"
						name="user_name"
						label="Your Name"
						required
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<ConnectedField
						datakey="user"
						name="user_email"
						label="Your Email Address"
						required
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<ConnectedField
						datakey="user"
						name="user_email_verify"
						label="Confirm Email Address"
						helperText="Must match entered email"
						required
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<ConnectedField
						datakey="user"
						name="user_password"
						label="Desired Password"
						type="password"
						helperText="Must be at least 10 characters"
						required
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<ConnectedField
						datakey="user"
						name="user_password_verify"
						label="Confirm Password"
						type="password"
						helperText="Must match desired password"
						required
					/>
				</Grid>

				<Grid item xs={6}>
					<Button
						color="secondary"
						variant="contained"
						size="large"
						className={classes.previousButton}
						onClick={this.handlePreviousSection}
					>
						Previous Section
					</Button>
				</Grid>

				<Grid item xs={6}>
					<Button
						color="primary"
						variant="contained"
						size="large"
						className={classes.nextButton}
						onClick={this.handleNextSection}
					>
						Next Section
					</Button>
				</Grid>
			</Grid>

		);
	}

}



const mapStateToProps = state => ({
	errors: state.register.userErrors.messages,
});

const mapDispatchToProps = dispatch => ({
	setState: (data) => (dispatch(actions.register.setState(data))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegisterUser));